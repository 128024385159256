import { gql } from "@apollo/client";
import { FilterOptionInput, PackageData } from "../../types";
import { createQueryHook } from "../GraphqlHelpers";

export interface PackageVariables {
  accountId: string;
  procoreProjectServerId: number;
  filters: FilterOptionInput[],
  searchValue: string,
  packagesLimit: number,
  packagesOffset: number,
  closeoutLogsLimit: number,
  closeoutLogsOffset: number,
}

export const GetPackagesQuery = gql`
query GetPackages(
$accountId: ID!,
$procoreProjectServerId: ID!,
$filters: [FilterOptionInput!]!,
$searchValue: String!,
$packagesLimit: Int!,
$packagesOffset: Int!,
$closeoutLogsLimit: Int!,
$closeoutLogsOffset: Int!) {
  packages(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $packagesLimit, offset: $packagesOffset) {
    id
    name
    status
    createdAt
    exportTo
    downloadExportUrl
    packageType
    exportSize
    packageQueries {
      id
      toolTitle
      selectedItemsCount
    }
    createdBy {
      id
      procoreUserName
    }
  }

  packageIds(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $packagesLimit, offset: $packagesOffset)
  closeoutLogIds(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $closeoutLogsLimit, offset: $closeoutLogsOffset)

  closeoutLogs(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $closeoutLogsLimit, offset: $closeoutLogsOffset) {
    id
    name
    logRequirementsCount
    logRequirementsCompleteCount
    createdAt
    substantialCompletionDate
    logFileRequestsReadyForReviewCount
  }

  templateCloseoutLogs(accountId: $accountId) {
    id
    name
    createdAt
  }
}
`;

export const useGetPackageQuery = createQueryHook<PackageData, PackageVariables>(GetPackagesQuery);