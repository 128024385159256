import React from 'react';
import Box from '@mui/material/Box'
import { Typography } from './shared/Typography';
import { VendorPortalLogFileRequest } from '../types';
import { Chip, CircularProgress, Link } from '@mui/material';
import { Colors } from '../styles';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { FileRecord } from './SubmitLogFileRequestsModal';

interface ISubmitLogFileRequestCard {
  logFileRequest: VendorPortalLogFileRequest;
  onFilesSelectedOrDropped: (newFiles: File[], logFileRequestId: number) => void;
  files: FileRecord[];
  onRetryFile: (fileRecordId: string) => void;
  onRemoveFile: (fileRecordId: string) => void;
}

const SubmitLogFileRequestCard = (props: ISubmitLogFileRequestCard): JSX.Element => {
  const hiddenFileInputRef = React.useRef(null);
  const [addMore, setAddMore] = React.useState(false);

  const [{ isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop: (data: DataTransfer) => {
      props.onFilesSelectedOrDropped(Array.from(data.files || []), props.logFileRequest.id);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <>
      <input type="file"
        multiple={true}
        ref={hiddenFileInputRef}
        onChange={(event) => {
          props.onFilesSelectedOrDropped(Array.from(event.target.files || []), props.logFileRequest.id);
          setAddMore(false);
          event.target.value = "";
        }}
        style={{display:'none'}}
      />
      {
        (props.files.length === 0 || addMore) && (
          <Box
            ref={drop}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '8px',
              height: props.files.length === 0 ? '200px' : '150px',
              borderColor: isOver ? Colors.newAccentBlue : Colors.reallyBlueGray,
              borderStyle: 'dashed',
              borderWidth: isOver ? '1.5px' : '1px',
              backgroundColor: isOver ? Colors.blue0 : Colors.white,
              transition:
                'borderColor 0.2s, borderWidth 0.2s, backgroundColor 0.3s',
              transitionTimingFunction: 'ease-in-out',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '6px',
                alignItems: 'center',
              }}
            >
              <Typography
                typestyle="ml"
                color={isOver ? Colors.newAccentBlue : Colors.darkerGray}
              >
                {isOver
                  ? 'Drop files to start uploading'
                  : 'Drag & drop files here'}
              </Typography>
              <Link
                underline="hover"
                color={isOver ? Colors.mediumDarkGray : Colors.newAccentBlue}
                onClick={() => {
                  hiddenFileInputRef.current.click();
                }}
                sx={{ cursor: 'pointer' }}
              >
                <Typography typestyle="xs">
                  or click here to browse for a file
                </Typography>
              </Link>
            </Box>
          </Box>
        )
      }
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'} flexDirection={'row'} gap={'8px'} flexWrap={'wrap'}>
          {
            props.files.map((fileRecord, index) => {
              return (
                fileRecord.status === 'failed' ? (
                  <Chip
                    key={`file-${index}`}
                    sx={{
                      backgroundColor: Colors.lightRed,
                      color: Colors.darkerRed,
                      '&.MuiChip-root .MuiChip-deleteIcon': {
                        fontSize: '0.75rem',
                        color: Colors.newAccentBlue,
                      }
                    }}
                    deleteIcon={<Typography sx={{ fontSize: '0.75rem', cursor: 'pointer'}} color={Colors.newAccentBlue} onClick={() => props.onRetryFile(fileRecord.id)}>Retry</Typography>}
                    label={fileRecord.file.name}
                    onDelete={() => {
                      props.onRetryFile(fileRecord.id);
                    }}
                  >
                  </Chip>
                ) : (
                  <Chip
                    avatar={(fileRecord.status === 'queued' || fileRecord.status === 'uploading') && <CircularProgress sx={{ color: Colors.blue5 }} size={16} variant="indeterminate" />}
                    key={`file-${index}`}
                    sx={{
                      backgroundColor: Colors.blue1,
                      color: Colors.newAccentBlue,
                    }}
                    label={fileRecord.file.name}
                    onDelete={fileRecord.status === 'complete'  ? () => {
                      props.onRemoveFile(fileRecord.id);
                    } : undefined}
                  >
                  </Chip>
                )
              );
            })
          }
        </Box>

        <Box minWidth={'fit-content'} width={'fit-content'}>
          {
            (props.files.length > 0) && (
              <Typography typestyle='m' color={Colors.newAccentBlue} sx={{
                '&:hover': {
                  cursor: 'pointer',
                  textDecoration: 'underline'
                }
              }} onClick={() => setAddMore(!addMore)}>{addMore ? 'Collapse' : 'Add more'}</Typography>
            )
          }
        </Box>
      </Box>
    </>
  );
};

export default SubmitLogFileRequestCard;
