import React, { createContext, useReducer } from 'react';
import { DEFAULT_PAGINATION_SIZE, PackageHistoryContextType, PackageHistoryState } from './PackageContext';
import { AuthContext } from "./AuthContext";
import { AccountProjectContext } from "./AccountProjectContextProvider";
import { useGetPackageQuery } from "../graphql/queries/GetPackages";
import { rootReducer } from './PackageContext';
import { useGetCloseoutAndPackageDataGridViews } from '../graphql/queries/GetCloseoutAndPackageDataGridViews';



const PackageHistoryContext = createContext<PackageHistoryContextType>({
  dispatch: () => undefined,
  fetchPackages: () => undefined,
  state: {} as PackageHistoryState,
});

const PackageHistoryContextProvider = (props: {
  children: React.ReactNode;
}): JSX.Element => {
  const { authenticated } = React.useContext(AuthContext);
  const { state: accountProjectState } = React.useContext(
    AccountProjectContext
  );
  const { loading, error, data, called, refetch } = useGetPackageQuery(
    {
      accountId: accountProjectState.accountId,
      procoreProjectServerId: accountProjectState.procoreProjectServerId,
      filters: [],
      searchValue: '',
      packagesLimit: DEFAULT_PAGINATION_SIZE,
      packagesOffset: 0,
      closeoutLogsLimit: DEFAULT_PAGINATION_SIZE,
      closeoutLogsOffset: 0,
    },
    !authenticated || !accountProjectState.procoreProjectServerId,
    'network-only'
  );

  const { loading: loadingDataGridViews, data: dataGridViewsData } = useGetCloseoutAndPackageDataGridViews(
    {
      accountId: accountProjectState.accountId,
    },
    !authenticated || !accountProjectState.procoreProjectServerId,
    'network-only'
  )

  const [state, dispatch] = useReducer(rootReducer, {
    loading: loading,
    data: data,
    error: error,
    displayPackageInProgressToast: false,
    displaySuccessToast: {isOpen: false, message: ''},
    tabValue: 'history',
    closeoutLogEnabled: false,
    packageListPaginationModel: {
      pageSize: DEFAULT_PAGINATION_SIZE,
      page: 0,
    },
    closeoutLogListPaginationModel: {
      pageSize: DEFAULT_PAGINATION_SIZE,
      page: 0,
    }
  });


  // TODO: Handle error or expose that via state to be handled by the consumer

  React.useEffect(() => {
    if (loadingDataGridViews || !dataGridViewsData) { return; }

    dispatch({
      type: 'SET_DATA_GRID_VIEWS',
      value: { data: dataGridViewsData },
    })

  }, [loadingDataGridViews, dataGridViewsData]);

  React.useEffect(() => {
    if (called) {
      dispatch({
        type: 'SET_LOADING',
        value: true,
      });

      refetch({
        accountId: accountProjectState.accountId,
        procoreProjectServerId: accountProjectState.procoreProjectServerId,
        filters: [],
        searchValue: '',
        packagesLimit: state.packageListPaginationModel.pageSize,
        packagesOffset: state.packageListPaginationModel.page * state.packageListPaginationModel.pageSize,
        closeoutLogsLimit: state.closeoutLogListPaginationModel.pageSize,
        closeoutLogsOffset: state.closeoutLogListPaginationModel.page * state.closeoutLogListPaginationModel.pageSize,
      })
        .then(() => {
          dispatch({
            type: 'SET_LOADING',
            value: false,
          });
        })
        .catch(() => {
          dispatch({
            type: 'SET_LOADING',
            value: false,
          });
        });
    }
  }, [state.packageListPaginationModel, state.closeoutLogListPaginationModel]);

  React.useEffect(() => {
    // Update loading whenever this effect is triggered.
    dispatch({
      type: 'SET_LOADING',
      value: loading,
    });

    if (loading || !data) {
      return;
    }

    dispatch({
      type: 'SET_PACKAGE_HISTORY',
      value: data,
    });
  }, [loading, data]);

  React.useEffect(() => {
    dispatch({
      type: 'SET_ERROR',
      value: error,
    });
  }, [error]);

  const fetchPackages = async () => {
    if (called && !state.loading) {
      dispatch({
        type: 'SET_LOADING',
        value: true,
      });

      refetch({
        accountId: accountProjectState.accountId,
        procoreProjectServerId: accountProjectState.procoreProjectServerId,
        filters: [],
        searchValue: '',
        packagesLimit: state.packageListPaginationModel.pageSize,
        packagesOffset: state.packageListPaginationModel.page * state.packageListPaginationModel.pageSize,
        closeoutLogsLimit: state.closeoutLogListPaginationModel.pageSize,
        closeoutLogsOffset: state.closeoutLogListPaginationModel.page * state.closeoutLogListPaginationModel.pageSize,
      })
        .then(() => {
          dispatch({
            type: 'SET_LOADING',
            value: false,
          });
        })
        .catch(() => {
          dispatch({
            type: 'SET_LOADING',
            value: false,
          });
        });
    }
  };

  return (
    <PackageHistoryContext.Provider value={{ state, dispatch, fetchPackages }}>
      {props.children}
    </PackageHistoryContext.Provider>
  );
};

export { PackageHistoryContext, PackageHistoryContextProvider };
