import { gql } from '@apollo/client';
import { CloseoutLogMetrics } from '../../types';
import { createQueryHook } from '../GraphqlHelpers';

interface CloseoutLogMetricsVariables {
  accountId: string;
}

export interface CloseoutLogMetricsData {
  closeoutLogMetrics: CloseoutLogMetrics[];
}

export const GetCloseoutLogMetricsQuery = gql`
  query GetCloseoutLogMetrics($accountId: ID!) {
    closeoutLogMetrics(accountId: $accountId) {
      id
      procoreProjectServerId
      logRequirementsCount
      logRequirementsCompleteCount
      logFileRequestsCount
      logFileRequestsCompleteCount
      logFileRequestsReadyForReviewCount
    }
  }
`;
export const useGetCloseoutLogMetrics = createQueryHook<CloseoutLogMetricsData, CloseoutLogMetricsVariables>(GetCloseoutLogMetricsQuery);
