import { gql } from "@apollo/client";
import { DataGridView } from "../../types";
import { createQueryHook } from "../GraphqlHelpers";

export interface CloseoutAndPackageDataGridData {
  packagesDataGridView: DataGridView;
  closeoutLogsDataGridView: DataGridView;
}

export interface CloseoutAndPackageDataGridViewsVariables {
  accountId: string;
}

export const GetCloseoutAndPackageDataGridViews = gql`
query GetCloseoutAndPackageDataGridViews($accountId: ID!) {
  packagesDataGridView: dataGridView(accountId: $accountId, frontendView: "packages") {
    id
    name
    columnDefinitions
    groupingColumnDefinition
    sortModel
    groupings
    columnVisibility
    filters
  }

  closeoutLogsDataGridView: dataGridView(accountId: $accountId, frontendView: "closeout_logs") {
    id
    name
    columnDefinitions
    groupingColumnDefinition
    sortModel
    groupings
    columnVisibility
    filters
  }
}
`;

export const useGetCloseoutAndPackageDataGridViews = createQueryHook<CloseoutAndPackageDataGridData, CloseoutAndPackageDataGridViewsVariables>(GetCloseoutAndPackageDataGridViews);