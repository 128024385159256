import React, { useState, useContext } from 'react';
import {
  Account,
  ExtractAutomation,
} from '../../types';
import { ExtractsCheckbox, StyledDataGridPremium } from '../../styles';
import { gridFilteredSortedRowIdsSelector, GRID_CHECKBOX_SELECTION_COL_DEF, GRID_CHECKBOX_SELECTION_FIELD, GridCell, GridCellParams, GridColDef, GridColumnHeaders, GridRow, GridRowId, useGridApiRef } from '@mui/x-data-grid-premium';
import { NewAutomationContext } from '../../contexts/EditAutomationContextProvider';
import useNavigationHook from '../../hooks/UseNavigationHook';
import { Box, Dialog, DialogContent, IconButton, Link, MenuItem, Popover, Tooltip, Typography } from '@mui/material';
import { ConfigureProjectsExtractAutomationModal } from '../automated-extract/ConfigureProjectsExtractAutomationModal';
import { Button, LoadingButton } from '../shared/Button';
import { Colors } from '../../styles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { useUpdateExtractAutomation } from '../../graphql/mutations/automation/UpdateExtractAutomation';
import StatusPill from '../shared/StatusPill';
import { ConfirmationModal } from '../shared/ConfirmationModal';
import { useDeleteExtractAutomations } from '../../graphql/mutations/automation/DeleteExtractAutomations';
import AutomationActivityModal from '../AutomationActivityModal';
import { useRefreshCompanyAutomationMetadata } from '../../graphql/mutations/RefreshCompanyAutomationMetadata';
import { useDuplicateExtractAutomation } from '../../graphql/mutations/automation/DuplicateExtractAutomation';
import { AutomationListSelectionPopup } from '../shared/AutomationListSelectionPopup';
import { format } from 'date-fns';

interface IAutomationListProps {
  account: Account;
  automations: ExtractAutomation[];
  refetchAccountAutomations: () => void;
  handleConfiguredAutomations: (accountId: string) => void;
}

const DEFAULT_COL_DEF = {
  editable: false,
  resizable: false,
  disableReorder: true,
  sortable: false,
  groupable: false,
  flex: 1,
};

export const AutomationList = ({
  account,
  automations,
  refetchAccountAutomations,
  handleConfiguredAutomations,
}: IAutomationListProps): JSX.Element => {
  const { dispatch } = useContext(NewAutomationContext);
  const navigateTo = useNavigationHook();
  const [enableProjectsModalAutomation, setEnableProjectsModalAutomation] = useState<ExtractAutomation>(null);
  const [loadingRefreshMetadata, setLoadingRefreshMetadata] = useState<boolean>(false);
  const [hoveredRowId, setHoveredRowId] = useState<string>(null);
  const [overflowAnchorEl, setOverflowAnchorEl] = useState<HTMLButtonElement>(null);
  const [currentContextAutomation, setCurrentContextAutomation] = useState<ExtractAutomation>(null);
  const [deleteAutomationModal, setDeleteAutomationModal] = useState<{isOpen: boolean, automationIds: number[]}>({ isOpen: false, automationIds: [] });
  const [disableAutomationModal, setDisableAutomationModal] = useState<{ isOpen: boolean; id: number; enabled: boolean }>(
    {
      isOpen: false,
      id: -1,
      enabled: false,
    }
  );
  const [automationRowSelectionModel, setAutomationRowSelectionModel] =  useState<GridRowId[]>([]);

  const [automationAnalyticModal, setAutomationAnalyticModal] = useState<{ isOpen: boolean; id: number; }>(
    {
      isOpen: false,
      id: -1
    }
  );
  const automationListDataGridApiRef = useGridApiRef();

  const [
    refreshCompanyAutomationMetadata,
  ] = useRefreshCompanyAutomationMetadata({
    accountId: account.id,
    configuredAutomations: true
  });

  const [
    updateExtractAutomation,
    { loading: updateExtractAutomationLoading, data: updateExtractAutomationData },
  ] = useUpdateExtractAutomation({
      accountId: account.id,
      extractAutomationId: -1,
      attributes: {}
  });

  const [deleteAutomation, { loading: loadingDeleteAutomation }] =
    useDeleteExtractAutomations({
    accountId: account.id,
    extractAutomationIds: []
  });

  const [duplicateAutomation, { loading: loadingDuplicateAutomation }] =
    useDuplicateExtractAutomation({
      accountId: account.id,
      extractAutomationId: -1
    });

  const toggleExtractAutomation = (id: number, enabled: boolean, confirm: boolean): void => {
    if (!enabled && confirm) {
      setDisableAutomationModal({
        isOpen: true,
        id: id,
        enabled: enabled,
      });
    } else {
      updateExtractAutomation({
        variables: {
          accountId: account.id,
          extractAutomationId: id,
          attributes: {
            enabled: enabled
          }
        }
      }).then((data) => {
        refetchAccountAutomations();
      });
    }
  }

  const dataGridColumnDefinitions: GridColDef[] = React.useMemo(() => {
    const selectedRowsMap = automationRowSelectionModel.reduce((acc, rowId) => {
      acc[rowId] = true;
      return acc;
    }, {});

    return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      field: GRID_CHECKBOX_SELECTION_FIELD,
      headerName: 'Select',
      renderHeader: () => {
        const filteredAndSortedRowIds = gridFilteredSortedRowIdsSelector(automationListDataGridApiRef.current.state, automationListDataGridApiRef.current.instanceId);
        const visibleIds = automationListDataGridApiRef.current.getAllRowIds().filter(rowId => filteredAndSortedRowIds.includes(rowId));
        return (
          <ExtractsCheckbox
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: '1.2rem',
              },
            }}
            checked={
              visibleIds.every(id => selectedRowsMap[id])
            }
            indeterminate={
              visibleIds.some(id => selectedRowsMap[id]) && !visibleIds.every(id => selectedRowsMap[id])
            }
            onChange={(e) => {
              if (e.target.checked) {
                setAutomationRowSelectionModel(
                  Array.from(new Set([...automationRowSelectionModel, ...visibleIds]))
                )
              } else {
                setAutomationRowSelectionModel(
                  automationRowSelectionModel.filter(id => !visibleIds.includes(id))
                )
              }
            }}
          />
        )
      },
      renderCell: (params: GridCellParams) => {
        return (
          <ExtractsCheckbox
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: '1.2rem',
              },
            }}
            checked={automationRowSelectionModel.includes(params.row.id as GridRowId)}
            onChange={(e) => {
              if (e.target.checked) {
                setAutomationRowSelectionModel([...automationRowSelectionModel, params.row.id]);
              } else {
                setAutomationRowSelectionModel(automationRowSelectionModel.filter( (id) => id !== params.row.id));
              }
            }}
          />
        )
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      ...DEFAULT_COL_DEF,
      type: 'string',
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      valueGetter: (value, row, column, apiRef) => {
        if (value) {
          if (typeof value === 'string') {
            return format(new Date(value), account.detailDateFormat);
          } else {
            return null;
          }
        } else {
          return null;
        }
      },
      ...DEFAULT_COL_DEF,
      type: 'string',
    },
    {
      field: 'enabledProjectsCount',
      headerName: 'Enabled Projects',
      ...DEFAULT_COL_DEF,
      type: 'custom',
      renderCell: (params) => {
        if (params.value > 0) {
          return (
            <Box display="flex" alignItems={'center'} gap="8px">
              <Link
                color={Colors.newAccentBlue}
                sx={{ cursor: 'pointer' }}
                underline="hover"
                onClick={(evt) => {
                  setEnableProjectsModalAutomation(params.row);
                  evt.stopPropagation();
                }}
              >
                {`${params.value} Project${params.value === 1 ? '' : 's'}`}
              </Link>
            </Box>
          );
        } else {
          return (
            <Button
              size="small"
              sx={{
                '&.MuiButtonBase-root': {
                  height: '32px',
                },
              }}
              variant="outlined"
              onClick={(evt) => {
                setEnableProjectsModalAutomation(params.row);
                evt.stopPropagation();
              }}
            >
              Enable Projects
            </Button>
          );
        }
      },
    },
    {
      ...DEFAULT_COL_DEF,
      field: 'enabled',
      type: 'custom',
      headerName: 'Automation Status',
      width: 60,
      renderCell: (params) => {
        return (
          <Box display={'flex'} alignItems={'center'}>
            <StatusPill status={params.value ? 'on' : 'off'}>
              <Typography>{params.value ? 'On' : 'Off'}</Typography>
            </StatusPill>
          </Box>
        );
      }
    },
    {
      ...DEFAULT_COL_DEF,
      field: 'actions',
      headerName: '',
      flex: 0,
      width: 180,
      editable: false,
      resizable: false,
      sortable: false,
      groupable: false,
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems={'center'} height={1} width={1}>
            {params.row.id === hoveredRowId && (
              <Tooltip title="Edit Automation" placement="top">
                <IconButton
                  onClick={() => {
                    const extractAutomation: ExtractAutomation = params.row;
                    dispatch({
                      type: 'SET_AUTOMATION',
                      value: {
                        accountId: account.id,
                        id: extractAutomation.id,
                        name: extractAutomation.name,
                        cloudStorageSystems: [],
                        toolAutomations: [],
                        sharedGroupings: [],
                      },
                    });
                    navigateTo(`/teams/${account.id}/automations/${extractAutomation.id}/edit`);
                  }}
                >
                  <EditOutlinedIcon sx={{ fontSize: '20px' }} />
                </IconButton>
              </Tooltip>
            )}

            {params.row.id === hoveredRowId && (
              <Tooltip title={params.row.enabled ? "The automation is currently active" : "The automation is currently inactive"} placement="top">
                <IconButton onClick={() => {
                  toggleExtractAutomation(params.row.id, !params.row.enabled, true);
                }}>
                  {params.row.enabled ? (<PauseCircleOutlineOutlinedIcon sx={{ fontSize: '20px' }} />) : (<PlayCircleFilledWhiteOutlinedIcon sx={{ fontSize: '20px' }} />)}
                </IconButton>
              </Tooltip>
            )}

            {params.row.id === hoveredRowId && (
              <Tooltip title={"Automation Activity"} placement="top">
                <IconButton onClick={() => {
                  setAutomationAnalyticModal({isOpen: true, id: params.row.id });
                }}>
                  <AssessmentOutlinedIcon sx={{ fontSize: '20px' }} />
                </IconButton>
              </Tooltip>
            )}

            {params.row.id === hoveredRowId && (
              <IconButton onClick={(evt) => {
                setCurrentContextAutomation(params.row);
                setOverflowAnchorEl(evt.currentTarget);
              }}>
                <MoreHorizIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            )}
          </Box>
        );
      },
    },
    ];
  }, [hoveredRowId, automationRowSelectionModel]);

  const MemoizedRow = React.memo(GridRow);
  const MemoizedCell = React.memo(GridCell);
  const MemoizedColumnHeaders = React.memo(GridColumnHeaders);

  const slots = React.useMemo(() => {
    return {
      row: MemoizedRow,
      cell: MemoizedCell,
      columnHeaders: MemoizedColumnHeaders,
    };
  }, []);

  return (
    <>
      {
        automationAnalyticModal.isOpen && (
          <AutomationActivityModal
            accountId={account.id}
            extractAutomationId={automationAnalyticModal.id}
            openModal={automationAnalyticModal.isOpen}
            closeModal={() => setAutomationAnalyticModal({ isOpen: false, id: -1 })}
          />
        )
      }
      <ConfirmationModal
        open={deleteAutomationModal.isOpen}
        headerText='Confirm Delete Automation'
        bodyText={`Are you sure you want to delete ${deleteAutomationModal.automationIds.length} automation(s)?`}
        submitText="Delete"
        onSubmit={() => {
          if (deleteAutomationModal.automationIds.length > 0) {
            deleteAutomation({
              variables: {
                accountId: account.id,
                extractAutomationIds: deleteAutomationModal.automationIds
              }
            }).then((data) => {
              refetchAccountAutomations();
            })
            setAutomationRowSelectionModel([]);
            setDeleteAutomationModal({isOpen: false, automationIds: [] });
          }
        }}
        onClose={ ()=> setDeleteAutomationModal({ isOpen: false, automationIds: [] })}
        submitLoading={loadingDeleteAutomation}
      />
      <ConfirmationModal
        open={disableAutomationModal.isOpen}
        headerText="Confirm Disable Automation"
        bodyText={`You have selected to disable an automation. Do you wish to continue anyway?`}
        submitText="Yes, Apply Changes"
        onSubmit={() => {
          toggleExtractAutomation(disableAutomationModal.id, disableAutomationModal.enabled, false);

          setDisableAutomationModal({
            isOpen: false,
            id: -1,
            enabled: false,
          });
        }}
        submitLoading={updateExtractAutomationLoading}
        onClose={() => {
          setDisableAutomationModal({
            isOpen: false,
            id: -1,
            enabled: false,
          });
        }}
      />
      <Dialog
        disableScrollLock
        open={Boolean(enableProjectsModalAutomation)}
        PaperProps={{
          sx: {
            padding: '0px',
            height: '100%',
            width: '100%',
            maxWidth: '1100px'
          },
        }}
      >
        <DialogContent
          sx={{
            padding: '0px',
            display: 'flex',
          }}
        >
          {enableProjectsModalAutomation && (
            <ConfigureProjectsExtractAutomationModal
              accountId={account.id}
              automation={enableProjectsModalAutomation}
              close={(refetchAutomations) => {
                setEnableProjectsModalAutomation(null);

                if (refetchAutomations) {
                  refetchAccountAutomations();
                }
              }}
            />
          )}
        </DialogContent>
      </Dialog>
      {
        account.configuredAutomations ? (
          <StyledDataGridPremium
            apiRef={automationListDataGridApiRef}
            checkboxSelection={true}
            columns={dataGridColumnDefinitions}
            disableColumnMenu
            slotProps={{
              row: {
                onMouseEnter: (
                  event: React.MouseEvent<HTMLDivElement, MouseEvent>
                ) => setHoveredRowId(event.currentTarget.dataset.id),
                onMouseLeave: () => setHoveredRowId(null),
              },
            }}
            slots={slots}
            rowSelectionModel={automationRowSelectionModel}
            rows={automations}
            rowSelection={false}
            hideFooter={true}
            hideFooterRowCount={true}
            initialState={{
              density: 'standard',
            }}
            columnHeaderHeight={40}
            sx={{
              '&.MuiDataGrid-root': {
                borderRadius: '8px 8px 0px 0px',
              },
              '& .MuiDataGrid-row:hover': {
                backgroundColor: 'inherit',
              },
              '& .MuiDataGrid-columnHeaderCheckbox': {
                padding: '0px',
              },
            }}
          />
        ) : (
          <Box
            display="flex"
            height={1}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{
              gap: '20px',
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              border: `1px solid ${Colors.mediumLightGray}`,
            }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
              <Typography variant="h6" style={{ color: '#171A1C', fontSize: '18px'}}>Get Started</Typography>
              <Typography variant="subtitle1" style={{ color: "#596A75", fontSize: '13px' }}>
                {"This is where you'll manage Extracts Pro Automations."}
              </Typography>
            </Box>
            <LoadingButton
                size="medium"
                buttonborderstyle="pill"
                variant="contained"
                loading={loadingRefreshMetadata}
                onClick={() => {
                  setLoadingRefreshMetadata(true);
                  refreshCompanyAutomationMetadata().then(() => {
                    setLoadingRefreshMetadata(false);
                    handleConfiguredAutomations(account.id);
                  }).catch(() => {
                    setLoadingRefreshMetadata(false);
                  });
                }}
              >
                Enable Automations
            </LoadingButton>
          </Box>
        )
      }

      <Popover
        id="automations-list-overflow-menu"
        disableScrollLock={true}
        slotProps={{ paper: { sx: { padding: '12px 0px' } } }}
        open={Boolean(overflowAnchorEl)}
        onClose={() => setOverflowAnchorEl(null)}
        anchorEl={overflowAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            setOverflowAnchorEl(null);
            duplicateAutomation({
              variables: {
                accountId: account.id,
                extractAutomationId: currentContextAutomation.id
              }
            }).then((data) => {
              if (data?.data?.duplicateExtractAutomation?.success) {
                refetchAccountAutomations();
                setCurrentContextAutomation(null);
              }
            })
          }}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOverflowAnchorEl(null);
            setDeleteAutomationModal({ isOpen: true, automationIds: [currentContextAutomation.id] });
            setCurrentContextAutomation(null);
          }}
        >
          Delete
        </MenuItem>
      </Popover>

      <AutomationListSelectionPopup
        open={automationRowSelectionModel.length > 0}
        rowSelectionModel={automationRowSelectionModel}
        onDeselect={() => {
          setAutomationRowSelectionModel([]);
        }}
        onDelete={() => {
          setDeleteAutomationModal({ isOpen: true, automationIds: automationRowSelectionModel as number[] });
        }}
       />
    </>
  );
};
