import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box'
import { Typography } from './shared/Typography';
import { LoadingButton } from './shared/Button';
import IconButton from '@mui/material/IconButton'
import Close from '@mui/icons-material/Close';
import { Button } from './shared/Button';
import { VendorPortalLogFileRequest } from '../types';
import { Divider, LinearProgress, linearProgressClasses, styled } from '@mui/material';
import { Colors } from '../styles';
import { useVendorPortalCreateProcoreProjectUpload } from '../graphql/mutations/VendorPortalCreateProcoreProjectUpload';
import { useVendorPortalCreateProcoreProjectFile } from '../graphql/mutations/VendorPortalCreateProcoreProjectFile';
import { v4 as uuidv4 } from 'uuid';
import { VendorPortalContext } from '../contexts/VendorPortalContextProvider';
import { useVendorPortalCreateLogFileRequestResponses, VendorPortalLogFileRequestResponseAttachmentInputType } from '../graphql/mutations/VendorPortalCreateLogFileRequestResponses';
import SubmitLogFileRequestCard from './SubmitLogFileRequestCard';
import CheckIcon from '@mui/icons-material/Check';

interface ISubmitLogFileRequestsModalProps {
  vendorId: string;
  logFileRequests: VendorPortalLogFileRequest[];
  modalIsOpen: boolean;
  openFileInputDialog: () => void;
  onCloseModal: () => void;
}

const StyledLinearProgress = styled(LinearProgress)(() => ({
  height: 9,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: Colors.lightGray,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: Colors.newAccentBlue
  },
}));

const CONCURRENT_UPLOADS = 3;

type FileUploadStatus =
  | 'uploading'
  | 'failed' // Either uploading to cloud storage or creating file failed
  | 'queued' // Waiting to be uploaded to cloud storage
  | 'complete' // File has been uploaded to cloud storage and created in Procore
  | 'canceled';

export type FileRecord = {
  id: string;
  file: File;
  logFileRequestId: number;
  progress: number;
  status: FileUploadStatus;
}

const SubmitLogFileRequestsModal = (props: ISubmitLogFileRequestsModalProps): JSX.Element => {
  const { state, dispatch } = React.useContext(VendorPortalContext);
  const [confirmCloseModal, setConfirmCloseModal] = useState<boolean>(false);

  const [files, setFiles] = useState<Record<string, FileRecord>>({});

  const [ createProcoreProjectUpload ] = useVendorPortalCreateProcoreProjectUpload({
    vendorId: props.vendorId,
    filename: ''
  });

  const [ createProcoreProjectFile ] = useVendorPortalCreateProcoreProjectFile({
    vendorId: props.vendorId,
    logFileRequestId: -1,
    filename: '',
    uploadUuid: ''
  });

  const [ createLogFileRequestResponses, { loading: loadingCreateLogFileRequestResponse }] = useVendorPortalCreateLogFileRequestResponses({
    vendorId: props.vendorId,
    responses: []
  });

  const uploadFileToCloudStorageAndCreateFile = (fileRecord: FileRecord, procoreUploadPayload: Record<string, any>): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        // Setup XMLHttpRequest
        const xhr = new XMLHttpRequest();

        xhr.open("POST", procoreUploadPayload['url'], true);

        xhr.upload.addEventListener('progress', function(event) {
          if (event.lengthComputable) {
            const percentComplete = (event.loaded / event.total) * 100;

            setFiles((files) => {

              const updatedFiles = { ...files };

              updatedFiles[fileRecord.id] = {
                ...fileRecord,
                status: 'uploading',
                progress: percentComplete
              }

              return updatedFiles;
            });
          }
        });

        // Handle successful upload
        xhr.addEventListener('load', async () => {
          if (xhr.status < 200 || xhr.status >= 300) {
            console.log('load error: Failed to upload file to cloud storage with status:', xhr.status);
            reject('Failed to upload file to cloud storage.');
          } else {
            try {
              const createFileResponse = await createProcoreProjectFile({
                variables: {
                  vendorId: props.vendorId,
                  logFileRequestId: fileRecord.logFileRequestId,
                  filename: fileRecord.file.name,
                  uploadUuid: procoreUploadPayload['uuid']
                }
              })

              if (createFileResponse.data.createProcoreProjectFile.success) {
                dispatch({
                  type: "APPEND_RESPONSE_ATTACHMENTS",
                  value: {
                    logFileRequestId: fileRecord.logFileRequestId,
                    attachments: [{
                      fileRecordId: fileRecord.id,
                      procoreFileServerId: createFileResponse.data.createProcoreProjectFile.procoreFileServerId,
                      filename: createFileResponse.data.createProcoreProjectFile.filename,
                      createdAt: createFileResponse.data.createProcoreProjectFile.createdAt,
                      createData: createFileResponse.data.createProcoreProjectFile.createData
                    }]
                  }
                });
                resolve(createFileResponse.data.createProcoreProjectFile.success);
              } else {
                reject('Failed to create Procore Project File');
              }
            } catch (error) {
              console.log('error', error);
              reject('Failed to create Procore Project File');
            }
          }
        });

        // Handle error
        xhr.addEventListener('error', function (error) {
          console.log('error', error);
          reject('Failed to upload file to cloud storage');
        });

        // Build the FormData
        const formData = new FormData();

        const directUploadFields = procoreUploadPayload['fields'] || {};

        Object.keys(directUploadFields).forEach((key) => {
          formData.append(key, directUploadFields[key]);
        });

        formData.append("file", fileRecord.file);

        // Send the request
        xhr.send(formData);
    });
  }

  const uploadFile = async (fileRecord: FileRecord): Promise<void> => {
    try {
      const response = await createProcoreProjectUpload({
        variables: {
          vendorId: props.vendorId,
          filename: fileRecord.file.name
        }
      });

      if (response.data.createProcoreProjectUpload.success) {
        await uploadFileToCloudStorageAndCreateFile(
          fileRecord,
          response.data.createProcoreProjectUpload.procoreUploadPayload
        );

        setFiles((files) => {
          const updatedFiles = { ...files };

          updatedFiles[fileRecord.id] = {
            ...fileRecord,
            status: 'complete'
          }

          return updatedFiles;
        });
      }
    } catch (error) {
      console.log('error', error);

      setFiles((files) => {
        const updatedFiles = { ...files };

        updatedFiles[fileRecord.id] = {
          ...fileRecord,
          status: 'failed'
        }

        return updatedFiles;
      });
    }
  }

  React.useEffect(() => {
    const fileRecords = Object.values(files);

    if (Object.keys(files).length > 0) {
      if (fileRecords.every((fileRecord) => fileRecord.status === 'complete' || fileRecord.status === 'canceled')) {
        console.log('do nothing')
      } else {
        const uploadingRecords = fileRecords.filter((fileRecords) => fileRecords.status === 'uploading');
        const count = uploadingRecords.length < CONCURRENT_UPLOADS ? CONCURRENT_UPLOADS - uploadingRecords.length : 0;
        const queuedRecords = fileRecords.filter((fileRecord) => fileRecord.status === 'queued').slice(0, count);

        if (queuedRecords.length > 0) {
          setFiles((files) => {

            const updatedFiles = { ...files };

            queuedRecords.reduce((acc, nextItem) => {
               acc[nextItem.id] = {
                 ...nextItem,
                 status: 'uploading'
               }

               return acc;
             }, updatedFiles);

            return updatedFiles;
          });

          queuedRecords.map((fileRecord) => {
            uploadFile({ ...fileRecord, status: 'uploading' });
          });
        }
      }
    }
  }, [files]);

  const handleOnClose = () => {
    dispatch({
      type: "CLEAR_RESPONSE_ATTACHMENTS"
    })

    props.onCloseModal();
  }

  const checkFormDirty = () => {
    return Object.values(files).length > 0 ? true : false;
  }

  const checkDirtyAndClose = () => {
    if (checkFormDirty()) {
      setConfirmCloseModal(true);
    }
    else {
      handleOnClose();
    }
  }

  const onFilesSelectedOrDropped = (newFiles: File[], logFileRequestId: number) => {
    setFiles((prevFiles) => {

      const updatedFiles = { ...prevFiles };

      newFiles.reduce((acc, file) => {
        const id = uuidv4();
        acc[id] = {
          id: id,
          file: file,
          logFileRequestId: logFileRequestId,
          progress: 0,
          status: 'queued'
        }

        return acc;
      }, updatedFiles);

      return updatedFiles;
    });
  };

  const allHaveAttachments = props.logFileRequests.every((logFileRequest) => {
    return (state.responseAttachments[logFileRequest.id] || []).length > 0
  });

  const disabled = !allHaveAttachments || Object.values(files).some((fileRecord) => fileRecord.status !== 'complete');

  return (
    <>
      <Dialog
        fullWidth
        disableScrollLock
        maxWidth="xs"
        open={confirmCloseModal}
        onClose={() => setConfirmCloseModal(false)}
      >
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Typography typestyle="xl">Unsaved Data</Typography>
            <Typography>{`If you leave this form the data you will lose any data you've entered. Would you like to leave anyway?`}</Typography>
          <Box display="flex" justifyContent="end" gap="8px">
            <Button
              variant="outlined"
              buttonborderstyle="pill"
              onClick={() => setConfirmCloseModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              buttonborderstyle="pill"
              onClick={handleOnClose}
            >
              Leave Anyway
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth={false}
        fullScreen
        disableScrollLock
        open={props.modalIsOpen}
        onClose={checkDirtyAndClose}
        slotProps={{
          paper: {
            sx: {
              position: 'absolute',
              width: 750,
              bottom: '0px',
              top: '0px',
              right: '0px',
            },
          }
        }}
      >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
          }}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '20px 24px',
            borderBottom: '1px solid #E4EBF0',
          }}>
            <Box display={'flex'} flexDirection={'column'} gap={'4px'}>
              <Typography typestyle="xl">{'Submit Files'}</Typography>
              <Box display={'flex'} flexDirection={'row'}>
                <Typography typestyle='xs' color={Colors.darkishGray}>{`${state.procoreProjectName} | ${state.vendorName}`}</Typography>
              </Box>
            </Box>
            <IconButton onClick={checkDirtyAndClose}>
              <Close sx={{fontSize: '18px'}}/>
            </IconButton>
          </Box>

          <Box display={'flex'} sx={{
            backgroundColor: Colors.lightestGray,
            flexDirection: 'column',
            overflowY: 'auto',
            gap: '20px',
            padding: '20px 24px',
            flexGrow: 1,
          }}>
            {
              props.logFileRequests.map((logFileRequest, index) => {
                const associatedFiles = Object.values(files).filter((fileRecord) => fileRecord.logFileRequestId === logFileRequest.id);
                return (
                  <Box key={`log-file-request-${index}`}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      borderRadius: '8px',
                      backgroundColor: Colors.white,
                      boxShadow: '0px 2px 4px 1px rgba(26, 32, 36, 0.04), 0px 4px 5px 0px rgba(26, 32, 36, 0.07), 0px 1px 10px 0px rgba(26, 32, 36, 0.06)',
                      width: '100%',
                      flexGrow: 0,
                    }}>
                    <Box display={'flex'} flexDirection={'row'} padding={'20px'} alignItems={'center'} gap={'16px'}>
                      <Box sx={{
                        height: '24px',
                        width: '24px',
                        borderRadius: '50%',
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: associatedFiles.length > 0 ? Colors.newAccentBlue : Colors.lightGray
                      }}>
                        {associatedFiles.length === 0 && <Box sx={{
                          width: '12px',
                          height: '12px',
                          borderRadius: '50%',
                          border: `1px dotted ${Colors.mediumDarkGray}`,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }} />}
                        {associatedFiles.length > 0 && <CheckIcon sx={{ color: Colors.white, fontSize: '16px' }} /> }
                      </Box>
                      <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
                        <Typography typestyle="l">{logFileRequest.title}</Typography>
                        <Typography typestyle="s">{logFileRequest.description}</Typography>
                      </Box>
                    </Box>

                    <Divider sx={{ color: Colors.mediumLightGray }} />

                    <Box padding={'20px'} display={'flex'} flexDirection={'column'} gap={'20px'}>
                      <SubmitLogFileRequestCard
                        logFileRequest={logFileRequest}
                        files={associatedFiles}
                        onFilesSelectedOrDropped={onFilesSelectedOrDropped}
                        onRetryFile={(fileRecordId) => {
                          setFiles((files) => {
                            const updatedFiles = { ...files };
                            updatedFiles[fileRecordId] = {
                              ...updatedFiles[fileRecordId],
                              status: 'queued'
                            }

                            return updatedFiles;
                          });
                        }}
                        onRemoveFile={(fileRecordId) => {
                          dispatch({
                            type: "REMOVE_RESPONSE_ATTACHMENT",
                            value: { logFileRequestId: logFileRequest.id, fileRecordId: fileRecordId }
                          });

                          setFiles((files) => {
                            const updatedFiles = { ...files };
                            delete updatedFiles[fileRecordId];
                            return updatedFiles;
                          });
                        }}
                        />
                    </Box>
                  </Box>
                );
              })
            }
          </Box>

          <Box display={'flex'} padding={'20px 28px'} gap={'8px'} flexDirection={'row'} justifyContent={'space-between'} alignContent={'center'} alignItems={'center'}
              sx={{
                boxShadow: '0px 2px 4px 1px rgba(26, 32, 36, 0.04), 0px 1px 10px 0px rgba(26, 32, 36, 0.06)'
              }}>
            <Box display={'flex'} flexDirection={'column'} gap={'6px'} sx={{ width: '175px'}}>
              <StyledLinearProgress variant='determinate' value={(new Set(Object.values(files).map(file => file.logFileRequestId)).size / props.logFileRequests.length) * 100} />
              <Typography typestyle='xxs' color={Colors.darkishGray}>{`${new Set(Object.values(files).map(file => file.logFileRequestId)).size} of ${props.logFileRequests.length} ready to submit`}</Typography>
            </Box>

            <Box display={'flex'} flexDirection={'row'} gap={'8px'}>
              <Button size='medium' variant="outlined" buttonborderstyle="pill" onClick={checkDirtyAndClose}>
                Cancel
              </Button>
              <LoadingButton
                size='medium'
                buttonborderstyle="pill"
                variant="contained"
                disabled={disabled}
                onClick={() => {
                  createLogFileRequestResponses({
                    variables: {
                      vendorId: props.vendorId,
                      responses: Object.values(files).map((fileRecord) => {
                        return {
                          logFileRequestId: fileRecord.logFileRequestId,
                          attachments: (state.responseAttachments[fileRecord.logFileRequestId] || []).map((attachment) => {
                            return {
                              procoreFileServerId: attachment.procoreFileServerId,
                              filename: attachment.filename,
                              createdAt: attachment.createdAt,
                              createData: attachment.createData
                            } as VendorPortalLogFileRequestResponseAttachmentInputType
                          })
                        }
                      })
                    }
                  }).then(({ data }) => {
                    if (data.createLogFileRequestResponses.success) {
                      dispatch({
                        type: "UPDATE_LOG_FILE_REQUESTS",
                        value: data.createLogFileRequestResponses.logFileRequests
                      });

                      handleOnClose();
                    }
                  })
                }}
                loading={loadingCreateLogFileRequestResponse}
              >
                {
                  'Submit Files'
                }
              </LoadingButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SubmitLogFileRequestsModal;
