import { CloseoutLogDataGridView, CloseoutLogExportOption, CloseoutLogVendorUrl, LogCustomField, LogFileRequest, LogFileRequestSubmitter, LogRequirement, LogRequirementItem, LogRequirementTrade, LogRequirementType, ProcoreTool, ProcoreToolGroupings, ResponsibleContractor, SpecSection } from "../types";
import { RequirementDescriptionImportOptions, SubmittalExtractOptions, SubmittalResponse } from "../types/submittal";
import { RfiExtractOptions } from "../types/rfi";
import { TOOL_ENGINE_NAMES, booleanValueOrFallback, getExtractOptionsForToolEngineName, parseDate, sortLogRequirementTrades, sortLogRequirementTypes, sortResponsibleContractors } from "../utils/utils";
import { DrawingExtractOptions } from "../types/drawing";
import { InspectionExtractOptions } from "../types/inspection";
import { ObservationExtractOptions } from "../types/observation";
import { FormExtractOptions } from "../types/form";
import { MeetingExtractOptions } from "../types/meeting";
import { PunchItemExtractOptions } from "../types/punch_item";
import { ActionPlanExtractOptions } from "../types/action_plan";

export type CloseoutExtractOptions = {
  include_table_of_contents: boolean,
  include_project_photo_on_cover_sheet: boolean;
  include_custom_cover_sheet: boolean;
  selectedGroupings: ProcoreToolGroupings[],
  include_description_in_table_of_contents: boolean;
  include_vendor_list: boolean;
  import_submittal_description_from: RequirementDescriptionImportOptions,
  external_storage_options?: {
    document_storage_system: string,
    storage_parent_id?: number
  };
  toolExtractOptions: ExtractToolOptions
}

export type ExtractToolOptions = {
  drawings: DrawingExtractOptions;
  forms: FormExtractOptions;
  inspections: InspectionExtractOptions;
  meetings: MeetingExtractOptions;
  observations: ObservationExtractOptions;
  punch_items: PunchItemExtractOptions;
  rfis: RfiExtractOptions;
  submittals: SubmittalExtractOptions;
  action_plans: ActionPlanExtractOptions;
};

export type CloseoutTab = 'log' | 'requests';

export type CloseoutLogState = {
  id: number | null;
  name: string;
  closeoutLogSearchValue: string;
  fileRequestSearchValue: string;
  substantialCompletionDate?: Date;
  logRequirements: LogRequirement[];
  logRequirementItems: Record<number, LogRequirementItem[]>;
  logFileRequestSubmitters: Record<number, number[]>;
  logFileRequests: LogFileRequest[];
  specSections: SpecSection[];
  responsibleContractors: ResponsibleContractor[];
  logRequirementTypes: LogRequirementType[];
  logCustomFields: LogCustomField[];
  logRequirementTrades: LogRequirementTrade[];
  procoreTools: ProcoreTool[];
  engineNameToProcoreTool: Record<string, ProcoreTool>;
  loadingLogData: boolean;
  selectedLogRequirementIds: number[];
  groupings: ProcoreToolGroupings[];
  displaySuccessToast: { isOpen: boolean, message: string };
  displayFailureToast: { isOpen: boolean, message: string };
  extractOptions: CloseoutExtractOptions;
  currentCloseoutLogDataGridView?: CloseoutLogDataGridView;
  currentDuplicateLogRequirementsDataGridView?: CloseoutLogDataGridView;
  currentCreateCloseoutLogFileRequestsDataGridView?: CloseoutLogDataGridView;
  currentCloseoutLogFileRequestDataGridView?: CloseoutLogDataGridView;
  selectedTab: CloseoutTab;
  closeoutLogVendorUrls: CloseoutLogVendorUrl[];
  submittalResponses: SubmittalResponse[];
};

export const initialState: CloseoutLogState = {
  id: null,
  name: null,
  closeoutLogSearchValue: '',
  fileRequestSearchValue: '',
  substantialCompletionDate: null,
  logRequirements: [],
  logFileRequests: [],
  logRequirementItems: {},
  logFileRequestSubmitters: {},
  specSections: [],
  responsibleContractors: [],
  logRequirementTypes: [],
  logCustomFields: [],
  logRequirementTrades: [],
  procoreTools: [],
  engineNameToProcoreTool: {},
  loadingLogData: true,
  groupings: [],
  displaySuccessToast: { isOpen: false, message: '' },
  displayFailureToast: { isOpen: false, message: '' },
  selectedLogRequirementIds: [],
  extractOptions: {
    selectedGroupings: [],
    include_table_of_contents: true,
    include_vendor_list: false,
    import_submittal_description_from: 'description',
    include_description_in_table_of_contents: false,
    include_custom_cover_sheet: false,
    include_project_photo_on_cover_sheet: false,
    external_storage_options: null,
    toolExtractOptions: {
      submittals: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.SUBMITTALS, {}) as SubmittalExtractOptions,
      rfis: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.RFIS, {}) as RfiExtractOptions,
      drawings: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.DRAWINGS, {}) as DrawingExtractOptions,
      meetings: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.MEETINGS, {}) as MeetingExtractOptions,
      forms: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.FORMS, {}) as FormExtractOptions,
      observations: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.OBSERVATIONS, {}) as ObservationExtractOptions,
      inspections: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.INSPECTIONS, {}) as InspectionExtractOptions,
      punch_items: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.PUNCH_LIST, {}) as PunchItemExtractOptions,
      action_plans: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.ACTION_PLANS, {}) as ActionPlanExtractOptions,
    },
  },
  currentCloseoutLogDataGridView: null,
  currentDuplicateLogRequirementsDataGridView: null,
  currentCreateCloseoutLogFileRequestsDataGridView: null,
  currentCloseoutLogFileRequestDataGridView: null,
  selectedTab: 'log',
  closeoutLogVendorUrls: [],
  submittalResponses: []
};

const SUPPORTED_CLOSEOUT_LOG_PROCORE_TOOL_ENGINE_NAMES = [
  TOOL_ENGINE_NAMES.SUBMITTALS,
  TOOL_ENGINE_NAMES.RFIS,
  TOOL_ENGINE_NAMES.DRAWINGS,
  TOOL_ENGINE_NAMES.PUNCH_LIST,
  TOOL_ENGINE_NAMES.PHOTOS,
  TOOL_ENGINE_NAMES.DOCUMENTS,
  TOOL_ENGINE_NAMES.INSPECTIONS,
  TOOL_ENGINE_NAMES.OBSERVATIONS,
  TOOL_ENGINE_NAMES.MEETINGS,
  TOOL_ENGINE_NAMES.FORMS,
  TOOL_ENGINE_NAMES.ACTION_PLANS,
];

const getSupportedCloseoutLogTools = (tools: ProcoreTool[]) => {
  return tools.filter(tool => {
    return SUPPORTED_CLOSEOUT_LOG_PROCORE_TOOL_ENGINE_NAMES.includes(tool.engineName);
  });
}

export type TOAST_TRIGGERS = 'UPDATE_LOG_REQUIREMENT'
  | 'APPEND_LOG_REQUIREMENTS'
  | 'APPEND_LOG_REQUIREMENT_ITEMS'
  | 'UPDATE_LOG_FILE_REQUEST'
  | 'DELETE_LOG_REQUIREMENT'
  | 'APPROVED_LOG_FILE_REQUEST'
  | 'REQUEST_REVISION_LOG_FILE_REQUEST'
  ;

const getDisplaySuccessToast = (current: { isOpen: boolean, message: string }, triggeredFrom?: TOAST_TRIGGERS, message?: string, ) => {
  if (!triggeredFrom) return current;

  switch (triggeredFrom) {
    case 'UPDATE_LOG_REQUIREMENT':
      return { isOpen: true, message: 'Changes saved' };

    case 'UPDATE_LOG_FILE_REQUEST':
      return { isOpen: true, message: 'Changes saved' };

    case 'APPROVED_LOG_FILE_REQUEST':
      return { isOpen: true, message: 'File request approved' };

    case 'REQUEST_REVISION_LOG_FILE_REQUEST':
      return { isOpen: true, message: 'File request requested revision.' };

    case 'APPEND_LOG_REQUIREMENTS':
      return { isOpen: true, message: message };

    case 'APPEND_LOG_REQUIREMENT_ITEMS':
      return { isOpen: true, message: message }

    case 'DELETE_LOG_REQUIREMENT':
      return { isOpen: true, message: 'Requirement successfully deleted'}

    default:
      return current;
  }
}

export type Action =
  { type: 'SET_CLOSEOUT_LOG_SEARCH_VALUE'; value: string }
  |
  { type: 'SET_FILE_REQUEST_SEARCH_VALUE'; value: string }
  |
  { type: "SET_CLOSEOUT_LOG"; value: { id: number; name: string, substantialCompletionDate?: string; }; }
  | { type: "SET_CLOSEOUT_LOG_DATA"; value: {
    responsibleContractors: ResponsibleContractor[];
    logRequirementTypes: LogRequirementType[];
    logCustomFields: LogCustomField[];
    logRequirementTrades: LogRequirementTrade[];
    logFileRequestSubmitters: LogFileRequestSubmitter[];
    specSections: SpecSection[];
    logRequirements: LogRequirement[],
    logRequirementItems: LogRequirementItem[],
    logFileRequests: LogFileRequest[],
    procoreTools: ProcoreTool[],
    groupings: ProcoreToolGroupings[],
    selectedTab?: CloseoutTab,
    currentCloseoutLogDataGridView: CloseoutLogDataGridView,
    currentDuplicateLogRequirementsDataGridView: CloseoutLogDataGridView,
    currentCreateCloseoutLogFileRequestsDataGridView: CloseoutLogDataGridView,
    currentCloseoutLogFileRequestDataGridView: CloseoutLogDataGridView,
    closeoutLogExportOption: CloseoutLogExportOption,
    closeoutLogVendorUrls: CloseoutLogVendorUrl[],
    submittalResponses: SubmittalResponse[],
  } }
  | { type: "SET_CLOSEOUT_LOG_DATA_LOADING"; value: boolean }
  | { type: "APPEND_LOG_REQUIREMENTS"; value: { logRequirements: LogRequirement[]; logRequirementItems: LogRequirementItem[], triggeredFrom: TOAST_TRIGGERS } }
  | { type: "APPEND_LOG_FILE_REQUESTS"; value: { logFileRequests: LogFileRequest[]; triggeredFrom: TOAST_TRIGGERS } }
  | { type: "UPDATE_LOG_REQUIREMENT"; value: { logRequirement: LogRequirement; logRequirementItems: LogRequirementItem[], triggeredFrom?: TOAST_TRIGGERS } }
  | { type: "UPDATE_LOG_FILE_REQUEST"; value: {
      logFileRequest: LogFileRequest;
      logRequirementItems: LogRequirementItem[],
      triggeredFrom?: TOAST_TRIGGERS,
      markRequirementAsComplete?: boolean,
    }
  }
  | { type: "DELETE_LOG_REQUIREMENTS"; value: { logRequirementIds: number[] } }
  | { type: "DELETE_LOG_FILE_REQUESTS"; value: { logFileRequestIds: number[] } }
  | { type: "APPEND_LOG_REQUIREMENT_ITEMS", value: { logRequirementId: number, logRequirementItems: LogRequirementItem[] } }
  | { type: "REMOVE_LOG_REQUIREMENT_ITEMS", value: { logRequirementId: number, logRequirementItemIds: number[] } }
  | { type: 'SET_SELECTED_GROUPINGS'; value: ProcoreToolGroupings[] }
  | { type: 'SET_SELECTED_TAB'; value: CloseoutTab }
  | { type: 'SET_EXTRACT_OPTIONS'; value: CloseoutExtractOptions }
  | { type: 'PREPEND_LOG_REQUIREMENT_TYPE'; value: LogRequirementType }
  | { type: 'APPEND_LOG_CUSTOM_FIELD'; value: LogCustomField }
  | { type: 'PREPEND_LOG_REQUIREMENT_TRADE'; value: LogRequirementTrade }
  | { type: 'ADD_AND_SORT_LOG_REQUIREMENT_TYPE'; value: LogRequirementType }
  | { type: 'ADD_AND_SORT_LOG_REQUIREMENT_TRADE'; value: LogRequirementTrade }
  | { type: 'DELETE_LOG_REQUIREMENT_TYPE'; value: number }
  | { type: 'DELETE_LOG_CUSTOM_FIELD'; value: number }
  | { type: 'DELETE_LOG_REQUIREMENT_TRADE'; value: number }
  | { type: 'UPDATE_LOG_REQUIREMENT_TYPE'; value: LogRequirementType }
  | { type: 'UPDATE_LOG_CUSTOM_FIELD'; value: LogCustomField }
  | { type: 'UPDATE_LOG_REQUIREMENT_TRADE'; value: LogRequirementType }
  | { type: 'SET_DISPLAY_SUCCESS_TOAST'; value: { isOpen: boolean, message: string } }
  | { type: 'SET_DISPLAY_FAILURE_TOAST'; value: { isOpen: boolean, message: string } }
  | { type: 'RENAME_CLOSEOUT_LOG'; value: string }
  | { type: 'UPDATE_CLOSEOUT_LOG'; value: { name: string; substantialCompletionDate?: string } }
  | { type: 'SET_AUTO_MARK_COMPLETE_CONFIG'; value: boolean }
  | { type: 'SET_AUTO_MARK_COMPLETE_ON_APPROVE_FILE_REQUEST'; value: boolean }
  | { type: 'UPDATE_LOG_REQUIREMENTS'; value: { logRequirements: LogRequirement[], logRequirementItems: LogRequirementItem[] } }
  | { type: 'UPDATE_CLOSEOUT_LOG_DATA_GRID_VIEW', value: CloseoutLogDataGridView }
  | { type: 'UPDATE_DUPLICATE_LOG_REQUIREMENTS_DATA_GRID_VIEW', value: CloseoutLogDataGridView }
  | { type: 'UPDATE_CLOSEOUT_LOG_FILE_REQUEST_DATA_GRID_VIEW', value: CloseoutLogDataGridView }
  | { type: 'UPDATE_CREATE_FILE_REQUESTS_DATA_GRID_VIEW', value: CloseoutLogDataGridView }
  | { type: 'UPDATE_LOG_FILE_REQUEST_SUBMITTERS', value: Record<number, number[]> }
  | { type: 'UPDATE_SELECTED_LOG_REQUIREMENT_IDS', value: number[] }

export const rootReducer: React.Reducer<CloseoutLogState, Action> = (
  state,
  action
) => {
  switch (action.type) {
    case "SET_CLOSEOUT_LOG":
      return {
        ...state,
        id: action.value.id,
        name: action.value.name,
        substantialCompletionDate: parseDate(action.value.substantialCompletionDate),
        logRequirements: [],
        logRequirementItems: {},
        logFileRequestSubmitters: {},
        logFileRequests: [],
        specSections: [],
        responsibleContractors: [],
        logRequirementTypes: [],
        logRequirementTrades: [],
        procoreTools: [],
        engineNameToProcoreTool: {},
        loadingLogData: true,
        groupings: [],
        displaySuccessToast: { isOpen: false, message: '' },
        displayFailureToast: { isOpen: false, message: '' },
        selectedLogRequirementIds: [],
        selectedTab: 'log',
        extractOptions: {
          selectedGroupings: [],
          include_vendor_list: false,
          include_table_of_contents: true,
          import_submittal_description_from: 'description',
          include_description_in_table_of_contents: false,
          include_custom_cover_sheet: false,
          include_project_photo_on_cover_sheet: false,
          external_storage_options: null,
          toolExtractOptions: {
            submittals: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.SUBMITTALS, {}) as SubmittalExtractOptions,
            rfis: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.RFIS, {}) as RfiExtractOptions,
            drawings: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.DRAWINGS, {}) as DrawingExtractOptions,
            meetings: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.MEETINGS, {}) as MeetingExtractOptions,
            forms: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.FORMS, {}) as FormExtractOptions,
            observations: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.OBSERVATIONS, {}) as ObservationExtractOptions,
            inspections: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.INSPECTIONS, {}) as InspectionExtractOptions,
            punch_items: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.PUNCH_LIST, {}) as PunchItemExtractOptions,
            action_plans: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.ACTION_PLANS, {}) as ActionPlanExtractOptions,
          }
        },
      };

    case 'ADD_AND_SORT_LOG_REQUIREMENT_TRADE': {
      const typeIds = state.logRequirementTrades.map((type) => type.id);

      if (typeIds.includes(action.value.id)) {
        return {
          ...state,
        };
      } else {
        return {
          ...state,
          logRequirementTrades: sortLogRequirementTrades([
            action.value,
            ...state.logRequirementTrades,
          ])
        }
      }
    }

    case 'UPDATE_SELECTED_LOG_REQUIREMENT_IDS': {
      return {
        ...state,
        selectedLogRequirementIds: action.value,
      }
    }

    case 'SET_CLOSEOUT_LOG_SEARCH_VALUE': {
      return {
        ...state,
        closeoutLogSearchValue: action.value,
      };
    }

    case 'SET_FILE_REQUEST_SEARCH_VALUE': {
      return {
        ...state,
        fileRequestSearchValue: action.value,
      };
    }

    case 'SET_CLOSEOUT_LOG_DATA_LOADING': {
      return {
        ...state,
        loadingLogData: action.value,
      }
    }

    case 'PREPEND_LOG_REQUIREMENT_TYPE': {
      return {
        ...state,
        logRequirementTypes: sortLogRequirementTypes([
          action.value,
          ...state.logRequirementTypes,
        ])
      }
    }

    case 'APPEND_LOG_CUSTOM_FIELD': {
      return {
        ...state,
        logCustomFields: [
          ...state.logCustomFields,
          action.value,
        ]
      }
    }

    case 'PREPEND_LOG_REQUIREMENT_TRADE': {
      return {
        ...state,
        logRequirementTrades: sortLogRequirementTrades([
          action.value,
          ...state.logRequirementTrades,
        ])
      }
    }

    case 'ADD_AND_SORT_LOG_REQUIREMENT_TYPE': {
      const typeIds = state.logRequirementTypes.map((type) => type.id);

      if (typeIds.includes(action.value.id)) {
        return {
          ...state,
        };
      } else {
        return {
          ...state,
          logRequirementTypes: sortLogRequirementTypes([
            action.value,
            ...state.logRequirementTypes,
          ])
        }
      }
    }

    case 'UPDATE_LOG_REQUIREMENT_TYPE': {
      return {
        ...state,
        logRequirementTypes: sortLogRequirementTypes(state.logRequirementTypes.reduce((acc, item) => {
          if (item.id === action.value.id) {
            acc.push(action.value);
          } else {
            acc.push(item);
          }
          return acc;
        }, []))
      }
    }

    case 'UPDATE_LOG_CUSTOM_FIELD': {
      return {
        ...state,
        logCustomFields: state.logCustomFields.reduce((acc, item) => {
          if (item.id === action.value.id) {
            acc.push(action.value);
          } else {
            acc.push(item);
          }
          return acc;
        }, [])
      }
    }

    case 'UPDATE_LOG_REQUIREMENT_TRADE': {
      return {
        ...state,
        logRequirementTrades: sortLogRequirementTrades(state.logRequirementTrades.reduce((acc, item) => {
          if (item.id === action.value.id) {
            acc.push(action.value);
          } else {
            acc.push(item);
          }
          return acc;
        }, []))
      }
    }

    case 'RENAME_CLOSEOUT_LOG': {
      return {
        ...state,
        name: action.value,
      }
    }

    case 'UPDATE_CLOSEOUT_LOG': {
      return {
        ...state,
        name: action.value.name,
        substantialCompletionDate: parseDate(action.value.substantialCompletionDate),
      }
    }

    case 'DELETE_LOG_REQUIREMENT_TYPE': {
      return {
        ...state,
        logRequirementTypes: sortLogRequirementTypes([
          ...state.logRequirementTypes.filter((logRequirementType) => logRequirementType.id != action.value)
        ])
      }
    }

    case 'DELETE_LOG_CUSTOM_FIELD': {
      return {
        ...state,
        logCustomFields: [
          ...state.logCustomFields.filter((logCustomField) => logCustomField.id != action.value)
        ]
      }
    }

    case 'DELETE_LOG_REQUIREMENT_TRADE': {
      return {
        ...state,
        logRequirementTrades: sortLogRequirementTrades([
          ...state.logRequirementTrades.filter((trade) => trade.id !== action.value)
        ])
      }
    }

    case 'SET_AUTO_MARK_COMPLETE_CONFIG': {
      return {
        ...state,
        currentCloseoutLogDataGridView: {
          ...state.currentCloseoutLogDataGridView,
          markRequirementCompleteOnFileUpload: action.value,
        }
      }
    }

    case 'SET_AUTO_MARK_COMPLETE_ON_APPROVE_FILE_REQUEST': {
      return {
        ...state,
        currentCloseoutLogFileRequestDataGridView: {
          ...state.currentCloseoutLogFileRequestDataGridView,
          markRequirementCompleteOnApprovedFileRequest: action.value,
        }
      }
    }

    case 'UPDATE_LOG_FILE_REQUEST_SUBMITTERS': {
      return {
        ...state,
        logFileRequestSubmitters: Object.keys(action.value).reduce((acc, responsibleContractorServerId) => {

          acc[responsibleContractorServerId] = [...(action.value[responsibleContractorServerId] || [])];

          return acc;
        }, state.logFileRequestSubmitters as Record<number, number[]>)
      };
    }

    case 'UPDATE_CLOSEOUT_LOG_DATA_GRID_VIEW': {
      return {
        ...state,
        currentCloseoutLogDataGridView: action.value,
      }
    }

    case 'UPDATE_DUPLICATE_LOG_REQUIREMENTS_DATA_GRID_VIEW': {
      return {
        ...state,
        currentDuplicateLogRequirementsDataGridView: action.value,
      }
    }

    case 'UPDATE_CREATE_FILE_REQUESTS_DATA_GRID_VIEW': {
      return {
        ...state,
        currentCreateCloseoutLogFileRequestsDataGridView: action.value,
      }
    }

    case 'UPDATE_CLOSEOUT_LOG_FILE_REQUEST_DATA_GRID_VIEW': {
      return {
        ...state,
        currentCloseoutLogFileRequestDataGridView: action.value,
      }
    }

    case "SET_CLOSEOUT_LOG_DATA": {
      const procoreTools = getSupportedCloseoutLogTools(action.value.procoreTools);
      const submittalExtractOptions = ((action.value.closeoutLogExportOption.toolExtractOptions || {})['submittals'] || {});
      const rfiExtractOptions = ((action.value.closeoutLogExportOption.toolExtractOptions || {})['rfis'] || {});
      const drawingExtractOptions = ((action.value.closeoutLogExportOption.toolExtractOptions || {})['drawings'] || {});
      const meetingExtractOptions = ((action.value.closeoutLogExportOption.toolExtractOptions || {})['meetings'] || {});
      const observationExtractOptions = ((action.value.closeoutLogExportOption.toolExtractOptions || {})['observations'] || {});
      const inspectionExtractOptions = ((action.value.closeoutLogExportOption.toolExtractOptions || {})['inspections'] || {});
      const punchListExtractOptions = ((action.value.closeoutLogExportOption.toolExtractOptions || {})['punch_items'] || {});
      const actionPlanExtractOptions = ((action.value.closeoutLogExportOption.toolExtractOptions || {})['action_plans'] || {});
      const formExtractsOptions = ((action.value.closeoutLogExportOption.toolExtractOptions || {})['forms'] || {});

      return {
        ...state,
        responsibleContractors: sortResponsibleContractors(action.value.responsibleContractors),
        logRequirementTypes: sortLogRequirementTypes(action.value.logRequirementTypes),
        logCustomFields: action.value.logCustomFields,
        logRequirementTrades: sortLogRequirementTrades(action.value.logRequirementTrades),
        specSections: action.value.specSections,
        logRequirements: action.value.logRequirements,
        selectedTab: action.value.selectedTab ? action.value.selectedTab : 'log',
        logFileRequestSubmitters: action.value.logFileRequestSubmitters.reduce((acc, submitter) => {
          acc[submitter.responsibleContractorServerId] ||= [];

          acc[submitter.responsibleContractorServerId].push(submitter.procoreUserServerId);

          return acc;
        }, {}),
        logRequirementItems: action.value.logRequirementItems.reduce((acc, logRequirementItem) => {
          acc[logRequirementItem.logRequirementId] ||= [];

          acc[logRequirementItem.logRequirementId].push(logRequirementItem);

          return acc;
        }, {} as Record<number, LogRequirementItem[]>),
        logFileRequests: action.value.logFileRequests,
        procoreTools: procoreTools,
        engineNameToProcoreTool: procoreTools.reduce((acc, tool) => {
          acc[tool.engineName] ||= tool;

          return acc;
        }, {}),
        groupings: action.value.groupings,
        loadingLogData: false,
        currentCloseoutLogDataGridView: action.value.currentCloseoutLogDataGridView,
        currentDuplicateLogRequirementsDataGridView: action.value.currentDuplicateLogRequirementsDataGridView,
        currentCreateCloseoutLogFileRequestsDataGridView: action.value.currentCreateCloseoutLogFileRequestsDataGridView,
        currentCloseoutLogFileRequestDataGridView: action.value.currentCloseoutLogFileRequestDataGridView,
        extractOptions: {
          ...state.extractOptions,
          include_table_of_contents: action.value.closeoutLogExportOption.includeTableOfContents,
          include_vendor_list: action.value.closeoutLogExportOption.includeVendorList,
          include_custom_cover_sheet: action.value.closeoutLogExportOption.includeCustomCoverSheet,
          include_project_photo_on_cover_sheet: action.value.closeoutLogExportOption.includeProjectPhotoOnCoverSheet,
          import_submittal_description_from: action.value.closeoutLogExportOption.importSubmittalDescriptionFrom,
          //selectedGroupings: for each selected groupings string, return the grouping that has the matching attribute string. Filter out undefined
          selectedGroupings: action.value.closeoutLogExportOption.groupings.map( (selectedGrouping) => {
            return action.value.groupings.find( grouping => grouping.attribute === selectedGrouping )
          }).filter(selectedGrouping => selectedGrouping),
          toolExtractOptions: {
            submittals: {
              attachment_option: submittalExtractOptions['attachment_option'] || 'distributed',
              workflow_responses: submittalExtractOptions['workflow_responses'] || [],
              single_pdf: true,
            },
            rfis: {
              response_option: rfiExtractOptions['response_option'] || 'official_only',
            },
            drawings: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.DRAWINGS, drawingExtractOptions) as DrawingExtractOptions,
            meetings: {
              single_pdf: booleanValueOrFallback(meetingExtractOptions['single_pdf'], true),
              image_attachment_option: meetingExtractOptions['image_attachment_option'] || 'thumbnails_and_full_size',
            },
            forms: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.FORMS, formExtractsOptions) as FormExtractOptions,
            observations: {
              include_linked_drawing_markup: booleanValueOrFallback(observationExtractOptions['include_linked_drawing_markup'], false),
              image_attachment_option: observationExtractOptions['image_attachment_option'] || 'thumbnails_and_full_size',
            },
            inspections: {
              single_pdf: booleanValueOrFallback(inspectionExtractOptions['single_pdf'], true),
              attachment_option: 'all',
              include_linked_drawings: booleanValueOrFallback(inspectionExtractOptions['include_linked_drawings'], true),
              show_item_response_change_activity: booleanValueOrFallback(inspectionExtractOptions['show_item_response_change_activity'], false),
              show_only_latest_response_change_activity: booleanValueOrFallback(inspectionExtractOptions['show_only_latest_response_change_activity'], true),
              include_linked_drawing_markup: booleanValueOrFallback(inspectionExtractOptions['include_linked_drawing_markup'], false),
              include_other_markup: booleanValueOrFallback(inspectionExtractOptions['include_other_markup'], false),
              include_observations: booleanValueOrFallback(inspectionExtractOptions['include_observations'], false),
              show_not_applicable_items: booleanValueOrFallback(inspectionExtractOptions['show_not_applicable_items'], true),
              collapse_not_applicable_sections: booleanValueOrFallback(inspectionExtractOptions['collapse_not_applicable_sections'], false),
              selected_attachment_options: {},
              image_attachment_option: inspectionExtractOptions['image_attachment_option'] || 'thumbnails_and_full_size',
              grouping_options: {},
            },
            punch_items: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.PUNCH_LIST, punchListExtractOptions) as PunchItemExtractOptions,
            action_plans: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.ACTION_PLANS, actionPlanExtractOptions) as ActionPlanExtractOptions,
          }
        },
        closeoutLogVendorUrls: action.value.closeoutLogVendorUrls,
        submittalResponses: action.value.submittalResponses,
      };
    }

    case "UPDATE_LOG_REQUIREMENT": {
      return {
        ...state,
        logRequirements: state.logRequirements.reduce((acc, logRequirement) => {
          if (logRequirement.id === action.value.logRequirement.id) {
            acc.push(action.value.logRequirement);
          } else {
            acc.push(logRequirement);
          }
          return acc;
        }, [] as LogRequirement[]),
        logRequirementItems: {
          ...state.logRequirementItems,
          ...action.value.logRequirementItems.reduce((acc, logRequirementItem) => {
            acc[logRequirementItem.logRequirementId] ||= [];

            acc[logRequirementItem.logRequirementId].push(logRequirementItem);

            return acc;
          }, { [action.value.logRequirement.id]: [] })
        },
        displaySuccessToast: getDisplaySuccessToast(state.displaySuccessToast, action.value.triggeredFrom),
      };
    }

    case "UPDATE_LOG_FILE_REQUEST": {
      const logRequirementId = action.value.logFileRequest.logRequirementId;
      return {
        ...state,
        logFileRequests: state.logFileRequests.reduce((acc, logFileRequest) => {
          if (logFileRequest.id === action.value.logFileRequest.id) {
            acc.push(action.value.logFileRequest);
          } else {
            acc.push(logFileRequest);
          }
          return acc;
        }, [] as LogFileRequest[]),
        logRequirementItems: {
          ...state.logRequirementItems,
          ...action.value.logRequirementItems.reduce((acc, logRequirementItem) => {
            acc[logRequirementItem.logRequirementId] ||= [];

            acc[logRequirementItem.logRequirementId].push(logRequirementItem);

            return acc;
          }, { [logRequirementId]: state.logRequirementItems[logRequirementId] || [] })
        },
        logRequirements: state.logRequirements.reduce((acc, logRequirement) => {
          if (logRequirement.id === logRequirementId) {
            if (action.value.markRequirementAsComplete) {
              acc.push({
                ...logRequirement,
                status: 'complete',
              });
            } else {
              acc.push(logRequirement);
            }
          } else {
            acc.push(logRequirement);
          }

          return acc;
        }, []),
        displaySuccessToast: getDisplaySuccessToast(state.displaySuccessToast, action.value.triggeredFrom),
      };
    }

    case "DELETE_LOG_REQUIREMENTS": {
      const idsToDelete = action.value.logRequirementIds.reduce((acc, logRequirementId)=> {
        acc.set(logRequirementId.toString(), true);

        return acc;
      }, new Map<string, boolean>());

      return {
        ...state,
        logFileRequests: state.logFileRequests.filter((logFileRequest) => {
          return !idsToDelete.has(logFileRequest.logRequirementId.toString())
        }),
        logRequirements: state.logRequirements.filter(logRequirement => !idsToDelete.has(logRequirement.id.toString())),
        logRequirementItems: Object.keys(state.logRequirementItems).reduce((acc, logRequirementId) => {
          if (!idsToDelete.has(logRequirementId.toString())) {
            acc[logRequirementId] = state.logRequirementItems[logRequirementId];
          }
          return acc;
        }, {}),
        displaySuccessToast: {isOpen: true, message: `Successfully deleted ${action.value.logRequirementIds.length} requirement${action.value.logRequirementIds.length===1 ? '' : 's'}.`}
      };
    }

    case "DELETE_LOG_FILE_REQUESTS": {
      const idsToDelete = action.value.logFileRequestIds.reduce((acc, logFileRequestId)=> {
        acc.set(logFileRequestId.toString(), true);

        return acc;
      }, new Map<string, boolean>)

      return {
        ...state,
        logFileRequests: state.logFileRequests.filter(logFileRequest => !idsToDelete.has(logFileRequest.id.toString())),
        displaySuccessToast: {isOpen: true, message: `Successfully archived ${action.value.logFileRequestIds.length} file request(s).`}
      };
    }

    case "APPEND_LOG_REQUIREMENTS": {
      // TODO: Need to add LogRequirementItems
      if (action.value.logRequirements.length > 0) {
        const newLogRequirementTypes: LogRequirementType[] = action.value.logRequirements.reduce((acc, logRequirement) => {
            if (logRequirement.logRequirementType) {
              acc.push(logRequirement.logRequirementType);
            }

            return acc;
          }, []);

        const newSpecSections: SpecSection[] = action.value.logRequirements.reduce((acc, logRequirement) => {
            if (logRequirement.specSection) {
              acc.push(logRequirement.specSection);
            }

            return acc;
          }, []);

        const newResponsibleContractors: ResponsibleContractor[] = action.value.logRequirements.reduce((acc, logRequirement) => {
            if (logRequirement.responsibleContractor) {
              acc.push(logRequirement.responsibleContractor);
            }

            return acc;
          }, []);

        return {
          ...state,
          logRequirements: [...state.logRequirements, ...action.value.logRequirements],
          logRequirementTypes: sortLogRequirementTypes(Object.values([
            ...state.logRequirementTypes,
            ...newLogRequirementTypes
          ].reduce((acc, item) => {
                if (!acc[item.id]) {
                  acc[item.id] = item;
                }

                return acc;
          }, {}))),
          specSections: Object.values([
            ...state.specSections,
            ...newSpecSections
          ].reduce((acc, item) => {
              if (!acc[item.id]) {
                acc[item.id] = item;
              }

              return acc;
          }, {})),
          responsibleContractors: sortResponsibleContractors(Object.values([
              ...state.responsibleContractors,
            ...newResponsibleContractors
            ].reduce((acc, item) => {
              if (!acc[item.id]) {
                acc[item.id] = item;
              }

              return acc;
          }, {}))),
          logRequirementItems: {
            ...state.logRequirementItems,
            ...action.value.logRequirementItems.reduce((acc, logRequirementItem) => {
              acc[logRequirementItem.logRequirementId] ||= [];

              acc[logRequirementItem.logRequirementId].push(logRequirementItem);

              return acc;
            }, {})
          },
          displaySuccessToast: getDisplaySuccessToast(
            state.displaySuccessToast,
            action.value.triggeredFrom,
            `${action.value.logRequirements.length} requirement(s) successfully created`

          ),
        };
      } else {
        return state;
      }
    }

    case "APPEND_LOG_FILE_REQUESTS": {
      if (action.value.logFileRequests.length > 0) {

        const assigneeCount = new Set(
          action.value.logFileRequests.map((logFileRequest) => logFileRequest.assignees)
          .flat()
          .map(assignee => assignee.procoreServerId)
        ).size;

        return {
          ...state,
          logFileRequests: [...state.logFileRequests, ...action.value.logFileRequests],
          displaySuccessToast: getDisplaySuccessToast(
            state.displaySuccessToast,
            action.value.triggeredFrom,
            `${action.value.logFileRequests.length} file requests(s) were created and ${assigneeCount} submitter(s) were notified.`

          ),
        };
      } else {
        return state;
      }
    }

    case "UPDATE_LOG_REQUIREMENTS":
      {
        //Goes through new logReqs and adds them to a new object by id
        const newReqsById = action.value.logRequirements.reduce((acc, logRequirement)=> {
          acc.set(logRequirement.id, logRequirement);
          return acc;
        }, new Map)

        //Goes through logReqs in state and adds either the newReq if it exists in newReqsById or it keeps the existing log req
        const reqsById = state.logRequirements.reduce((acc, logRequirement) => {
          acc.set(logRequirement.id, newReqsById.get(logRequirement.id) || logRequirement);
          return acc;
        }, new Map)

        return {
          ...state,
          logRequirements: Array.from(reqsById.values()),
          logRequirementItems: {
            ...state.logRequirementItems,
            ...action.value.logRequirementItems.reduce((acc, logRequirementItem) => {
              acc[logRequirementItem.logRequirementId] ||= [];

              acc[logRequirementItem.logRequirementId].push(logRequirementItem);

              return acc;
            }, action.value.logRequirements.reduce((acc, logRequirement) => {
              acc[logRequirement.id] ||= [];

              return acc;
            }, {}))
          },
          displaySuccessToast: {
            isOpen: true,
            message: `Successfully updated ${action.value.logRequirements.length} requirements.`
          }
        }
      }

    case "SET_DISPLAY_SUCCESS_TOAST":
      return {
        ...state,
        displaySuccessToast: action.value
      };

    case "SET_DISPLAY_FAILURE_TOAST":
      return {
        ...state,
        displayFailureToast: action.value
      };

    case "SET_SELECTED_GROUPINGS":
      return {
        ...state,
        extractOptions: {
          ...state.extractOptions,
          selectedGroupings: action.value
        }
      };

    case "SET_SELECTED_TAB":
      return {
        ...state,
        selectedTab: action.value
      };

    case 'SET_EXTRACT_OPTIONS': {
      return {
        ...state,
        extractOptions: {
          ...state.extractOptions,
          ...action.value
        }
      };
    }

    case 'APPEND_LOG_REQUIREMENT_ITEMS': {
      if (action.value.logRequirementItems.length === 0) {
        return state;
      } else {
        const existingLogRequirementItems = (state.logRequirementItems[action.value.logRequirementId] || []);
        return {
          ...state,
          displaySuccessToast: getDisplaySuccessToast(
            state.displaySuccessToast,
            'APPEND_LOG_REQUIREMENT_ITEMS',
            `${action.value.logRequirementItems.length} item(s) successfully linked`
          ),
          // TODO: Need to use reduce here
          logRequirementItems: {
            ...state.logRequirementItems,
            [action.value.logRequirementId]: [
              ...existingLogRequirementItems,
              ...action.value.logRequirementItems
            ]
          }
        }
      }
    }

    case 'REMOVE_LOG_REQUIREMENT_ITEMS': {
      const existingLogRequirementItems = (state.logRequirementItems[action.value.logRequirementId] || []);

      return {
        ...state,
        logRequirementItems: {
            ...state.logRequirementItems,
            ...existingLogRequirementItems.reduce((acc, logRequirementItem) => {
              acc[logRequirementItem.logRequirementId] ||= [];
            if (!action.value.logRequirementItemIds.includes(logRequirementItem.id)) {
              acc[logRequirementItem.logRequirementId].push(logRequirementItem);
            }

            return acc;
          }, {  [action.value.logRequirementId]: [] }),
        }
      }
    }

    default:
      return state;
  }
};

export type CloseoutLogContextState = {
  state: CloseoutLogState;
  dispatch: (action: Action) => void;
};
